import * as THREE from 'three';

const loader = new THREE.TextureLoader();

// const texture = loader.load('/textures/map.png');
const texture = loader.load('/textures/30.02.png');

const normalMapSize = 300000;
const renderedMapSize = 250000;

texture.repeat.set(renderedMapSize / normalMapSize, renderedMapSize / normalMapSize);
texture.center.set(0.5, 0.5);
texture.rotation = -Math.PI / 2;
texture.offset.set(-500 / 299926, -8508 / 299926);

const mapMaterial = new THREE.MeshBasicMaterial({
  // color: 0x00ff00,
  // wireframe: true,
  map: texture,
});

export default mapMaterial;
